require.context('../images', true)
import Rails from "@rails/ujs"
import {
  Dropdown,
  Alert
} from 'bootstrap';
Rails.start()


document.addEventListener("DOMContentLoaded", function () {
  userForm()
})

function userForm() {
  const elements = document.querySelectorAll('[data-inputs]')
  for (const el of elements) {
    const json = JSON.parse(el.getAttribute('data-inputs'))
    const inputs = getAllInput(json);

    if (el.type === 'checkbox') {
      if (el.checked) { showing(el.value, json, inputs) }
      el.addEventListener('change', (e) => {
        e.target.checked ? showing(el.value, json, inputs) : hidden(el.value, json, inputs)
      })
    }
    if (el.type === 'radio') {
      if (el.checked) { showing(el.value, json, inputs) }
      el.addEventListener('change', (e) => {
        hidden(el.value, json, inputs)
        showing(el.value, json, inputs)
      })
    }
    if (el.type === 'select-one') {
      hidden(el.value, json, inputs)
      showing(el.value, json, inputs)
      el.addEventListener('change', (e) => {
        const value = e.target.value;
        hidden(value, json, inputs)
        showing(value, json, inputs)
      })
    }
  }

  function hidden(value, json, inputs) {
    const provideInputs = getProvideInputs(json, value)
    if (!provideInputs && provideInputs.lenght === 0) return;

    for (const input of inputs) {
      const el = document.querySelector(`[data-form-name="${input}"]`);
      if (!el) continue

      el.classList.add('hidden')
    }
  }

  function showing(value, json, inputs) {
    const provideInputs = getProvideInputs(json, value)
    if (!provideInputs && provideInputs.lenght === 0) return;

    for (const input of provideInputs) {
      const el = document.querySelector(`[data-form-name="${input}"]`);
      if (!el) continue

      el.classList.remove('hidden')
    }
  }

  function getProvideInputs(json, value) {
    if (typeof json[0] == 'string') return json;

    const find = json.filter(t => t.value === value)
    if (!find && find.lenght !== 1) return [];

    return find[0].provideInputs;
  }

  function getAllInput(json) {
    if (typeof json[0] == 'string') return json;

    return [...new Set(json.map(p => p.provideInputs).flat())];
  }
}